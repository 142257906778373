function HaulerPage() {
  return (
    <div>
      <h1>Hauler Portal</h1>
      <p>Welcome to the Hauler portal of Sendeliver!</p>
      {/* Ďalší obsah pre Hauler */}
    </div>
  );
}

export default HaulerPage;

