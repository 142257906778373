function SenderPage() {
  return (
    <div>
      <h1>Sender Portal</h1>
      <p>Welcome to the Sender portal of Sendeliver!</p>
      {/* Ďalší obsah pre Sender */}
    </div>
  );
}

export default SenderPage;
